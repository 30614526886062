<template>
  <div v-if="formData">
    <contactComments
      :contact-id="contactId"
      :contact="formData"
      :form-key="formKey"
    ></contactComments>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  computed: {
    contactId() {
      return this.formData.id
    },
  },
  components: {
    contactComments: () => import('modules/contact/components/contactComments/contactComments.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
